// If you want to override variables do it here
@import "variables";

// Import CoreUI styles
@import "~@coreui/coreui/scss/coreui.scss";

// If you want to add something do it here
@import "custom";


// 	body {
// 	  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
// 	  text-align: center;
// 	}

//   * {
//     font-size: 9px;
//     // color: #000;
//   }


// 	body h1 {
// 	  font-weight: 300;
// 	  margin-bottom: 0px;
// 	  padding-bottom: 0px;
// 	  color: #000;
// 	}

// 	body h3 {
// 	  font-weight: 300;
// 	  // margin-top: 10px;
// 	  // margin-bottom: 20px;
// 	  font-style: italic;
// 	  color: #555;
// 	}

// 	body a {
// 	  color: #06f;
// 	}



// 	.invoice-box {
// 	  // max-width: 800px;
// 	  // margin: auto;
// 	  padding-left: 30px;
// 	  padding-right: 30px;
//     padding-bottom:10px;
// 	  border: 1px solid #eee;
// 	  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
// 	  // font-size: 16px;
// 	  line-height: 24px;
// 	  font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
// 	  color: #555;
// 	}

// 	.invoice-box table {
// 	  width: 100%;
// 	  line-height: inherit;
// 	  text-align: left;
// 	    // text-align: center;
// 	  border-collapse: collapse;
// 	}

// 	.invoice-box table td {
// 	  padding: 2px;
// 	  vertical-align: top;
// 	}

// 	.invoice-box table tr td:nth-child(2) {
// 	  // text-align: right;
// 	    // text-align: center;
// 	}

// 	.invoice-box table tr.top table td {
// 	  // padding-bottom: 20px;
// 	  padding-bottom: 0px;
// 	}

// 	.invoice-box table tr.top table td.title {
// 	  // font-size: 45px;
// 	  line-height: 45px;
// 	  color: #333;
// 	}

// 	.invoice-box table tr.information table td {
// 	  // padding-bottom: 40px;
// 	  padding-bottom: 0px;
// 	}

// 	.invoice-box table tr.heading td {
// 	  // background: #eee;
// 	  border-bottom: 1px solid #ddd;
// 	  font-weight: bold;
// 	}

// 	.invoice-box table tr.details td {
// 	  // padding-bottom: 20px;
// 	  padding-bottom: 0px;
// 	}

// 	.invoice-box table tr.item td {
// 	  border-bottom: 1px solid #eee;
// 	}

// 	.invoice-box table tr.item.last td {
// 	  border-bottom: none;
// 	}

// 	.invoice-box table tr.total td:nth-child(2) {
// 	  border-top: 2px solid #eee;
// 	  font-weight: bold;
// 	}

// 	@media only screen and (max-width: 600px) {
// 	  .invoice-box table tr.top table td {
// 	    width: 100%;
// 	    display: block;
// 	    text-align: center;
// 	  }

// 	  .invoice-box table tr.information table td {
// 	    width: 100%;
// 	    display: block;
// 	    text-align: center;
// 	  }
// 	}

// #content{
//   width:595px;
//   height: 842px;
// }

// .border {
//   border: 1px solid #ddd;
// }
// .border-l {
//     border-left: 1px solid #ddd;
// }

// .border-b{
//     border-bottom: 1px solid #ddd;
// }

// .border-t{
//     border-top: 1px solid #ddd;
// }

// .text-align-right{
//   text-align: right;
// }
// .text-align-bottom{
//   vertical-align:bottom;
//   text-align: bottom;
// }

// .font-bold{
//   font-weight: bold;
// }

// .font-15{
//   font-size: 15px;
// }
// .font-12{
//   font-size: 12px;
// }

// .no-padding{
//   padding-bottom: 0px;
// }

.margin-left-10{
  margin-left: 10px;
}

.margin-top-20{
  margin-top: 20px;
}

.display-image{
  width: 200px;
}
