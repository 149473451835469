// Here you can add other styles

.display-none{
    display: none;
}

.margin-top-20{
  margin-top: 20px;
}

.margin-left-10{
  margin-left: 10px;
}

.margin-bottom-20{
  margin-bottom: 20px;
}

.margin-right-10{
  margin-right: 10px;
}
